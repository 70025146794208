/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved, import/extensions, import/no-extraneous-dependencies */
import React, { Component } from 'react';
import {

  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from 'react-share';


import exampleImage from './logo_n.png';

class Demo extends Component {
  render() {
    const shareUrl = 'https://splitandmergepdf.com';
    const title = 'Split and merge PDFs online';

    return (
      <div className="Demo__container">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={shareUrl} >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <VKShareButton
            url={shareUrl}
            image={`${String(window.location)}/${exampleImage}`}
          >
            <VKIcon size={32} round />
          </VKShareButton>

          <OKShareButton
            url={shareUrl}
            image={`${String(window.location)}/${exampleImage}`}
          >
            <OKIcon size={32} round />
          </OKShareButton>

          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <TumblrShareButton
            url={shareUrl}
            title={title}
          >
            <TumblrIcon size={32} round />
          </TumblrShareButton>

          <LivejournalShareButton
            url={shareUrl}
            title={title}
            description={shareUrl}

          >
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>

          <MailruShareButton
            url={shareUrl}
            title={title}

          >
            <MailruIcon size={32} round />
          </MailruShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={title}
            body={title}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>

          <ViberShareButton
            url={shareUrl}
            title={title}

          >
            <ViberIcon size={32} round />
          </ViberShareButton>

          <WorkplaceShareButton
            url={shareUrl}
            quote={title}
          >
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <LineShareButton
            url={shareUrl}
            title={title}
          >
            <LineIcon size={32} round />
          </LineShareButton>
          <WeiboShareButton
            url={shareUrl}
            title={title}
            image={`${String(window.location)}/${exampleImage}`}
          >
            <WeiboIcon size={32} round />
          </WeiboShareButton>

          <PocketShareButton
            url={shareUrl}
            title={title}
          >
            <PocketIcon size={32} round />
          </PocketShareButton>

          <InstapaperShareButton
            url={shareUrl}
            title={title}

          >
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>

          <HatenaShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
          >
            <HatenaIcon size={32} round />
          </HatenaShareButton>

      </div>
    );
  }
}

export default Demo;
