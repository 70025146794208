import './App.css';
import { PDFDocument,rgb } from 'pdf-lib'
import download from 'downloadjs';
import React from 'react';
import { MdZoomOutMap } from "react-icons/md"
import { GrDocumentPdf } from "react-icons/gr";
import { FaPlus,FaMinus,FaDownload,FaTrash,FaUpload,FaFile,FaCogs,FaFileImage,FaArrowLeft,FaArrowRight } from "react-icons/fa";
import { AiOutlineFileJpg } from "react-icons/ai";

import { Dropdown,Button,ButtonGroup,Navbar,Container,Modal } from 'react-bootstrap';
import {fromEvent} from "file-selector";
import Dropzone from 'react-dropzone';
import { ReactSortable } from "react-sortablejs";
import {extractImages,extractResize,resizeImage} from "./extractImages"
import logo from './logo_n.png';

class App extends React.Component {
  constructor(){
    super();
    this.state={
      iframe : '',
      error : "",
      els:[],
      dropModalShow : false,
      dropModalText : {},
      loadedFiles : [],
      newDocModalShow:false,
      settingsModalShow:false,
      overlay: false,
      counter : false,
      resize : false,
      quality : 80,
      resolution:80,
      resizeModalShow:false,
      images :[]
    }
  }
  componentDidMount(){
    document.getElementById("manual").appendChild(document.getElementById("content"));
  }
  openPDF= async(data)=>{
    const pdfDoc = await PDFDocument.load(data)
    const pages = pdfDoc.getPages()

    let singlePages = [];
    pages.forEach(async(p,i)=>{
      let newDoc = await PDFDocument.create();
      let newPage = await newDoc.copyPages(pdfDoc, [i])
      newDoc.addPage(newPage[0]);
      let pdfBytes = await newDoc.save();
      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      let docUrl = URL.createObjectURL(blob);
      singlePages.push(docUrl);
      if(i+1===pages.length){
        this.setState({pages : singlePages,originalDoc:pdfDoc });
      }
    })
  }
  show=(doc,filename)=>{
    let els = this.state.els;
    let self=this;
    var pdfjsLib = window['pdfjs-dist/build/pdf'];
    //console.log(pdfjsLib)
    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
    var loadingTask = pdfjsLib.getDocument(doc);
    loadingTask.promise.then(function(pdf) {
      var pageNumber;
      for(let i = 0;i<pdf._pdfInfo.numPages;i++){
      //  let myRef = React.createRef();
        pageNumber=i+1;
        let index = els.length+pageNumber;
      pdf.getPage(pageNumber).then(function(page) {
        var scale = 1.5;
        var viewport = page.getViewport({scale: scale});
        var canvas = document.createElement("canvas")
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);
        renderTask.promise.then(function () {
          let img = canvas.toDataURL('image/jpeg', 1.0);
          els.push({ img : img, zoom:false,filename:filename,page : i, id:index});
          els.sort((a, b) => (a.id > b.id) ? 1 : -1)
            self.setState({els:els});
        });



      });
    }
    }, function (reason) {
      // PDF loading error
      console.error(reason);
    });

  }
  loadFile = (e)=>{
    let files = e.target.files;
    for(let i =0;i<files.length;i++){
      if(files[i].type!=="application/pdf"){
        this.setState({error : files[i].name + " was not recognized as PDF file" })
        setTimeout(()=>{
          this.setState({error : "" })
        },4000)
        continue;
      }
      let reader = new FileReader();
      reader.readAsArrayBuffer(files[i]);
      reader.onload = ()=> {
        //this.openPDF(reader.result)
        this.show(reader.result,files[i].name)
        let loadedFiles = this.state.loadedFiles;
        loadedFiles.push({data:reader.result,filename:files[i].name});
        this.setState({loadedFiles:loadedFiles})
      };
      reader.onerror = function() {
        console.log(reader.error);
      };
    }
  }
  download =async()=>{
    try{
      let files = [];
      for(let i =0;i<this.state.loadedFiles.length;i++){
         let doc = await PDFDocument.load(this.state.loadedFiles[i].data);
         files.push({doc : doc, name: this.state.loadedFiles[i].filename})
       };
      let newDoc = await PDFDocument.create();
      let newPages = [];
      for(let i =0; i<this.state.els.length;i++){
        let originalDoc = files.find((f)=>{ return f.name ===this.state.els[i].filename });
        let page;
        if(this.state.resize===true){
          let tempDoc = await PDFDocument.create();
          let pageCopy = await tempDoc.copyPages(originalDoc.doc, [this.state.els[i].page]);
          let img = await extractResize(pageCopy,this.state.quality,this.state.resolution);
          let tempDoc2 = await PDFDocument.create();
          let temppage = tempDoc2.addPage();
          let jpgImage = await tempDoc2.embedJpg(img.content)
          temppage.drawImage(jpgImage,{
            x:0,
            y:0,
            width: temppage.getWidth(),
            height : temppage.getHeight(),
          })
          page = await newDoc.copyPages(tempDoc2, [0]);
        }else {
          page = await newDoc.copyPages(originalDoc.doc, [this.state.els[i].page]);
        }

        newPages.push(page[0]);
      }
      newPages.forEach((p,i)=>{
        let size = p.getSize();
        let w = size.width/2-10;

        if(this.state.overlay===true){
          p.drawRectangle({
            x: 0,
            y: 0,
            width: size.width,
            height: 40,
            borderWidth: 0,
            color: rgb(1,1,1)
          })
        }
        if(this.state.counter){
          p.drawText((i+1) +" of "+ this.state.els.length, { x: w, y: 20, size: 8 });
        }
        newDoc.addPage(p)
      })
      let p =newDoc.getPage(0)
      newDoc.setCreator('splitandmergepdf')
      let pdfBytes = await newDoc.save();
      let name = "splitandmergepdf.pdf";
      if(this.state.loadedFiles.length===1){
        name=this.state.loadedFiles[0].filename;
      }
      download(pdfBytes,name , "application/pdf");
    }catch(err){
      console.log(err)
    }
  }
  pageSelect = (e,num)=>{
    let list = [...this.state.list];
    if(e.target.checked===true){
      list.push(num)
    }else {
      list = list.filter((n)=>{ return n!==num })
    }
    this.setState({list:list});
  }
  zoom = (imgData)=>{
    let div = document.createElement("div");
    div.className="fullscreen";
    let img = document.createElement("img");
    img.src = imgData;
    div.appendChild(img);
    div.addEventListener("click",()=>{
      div.remove();
    });
    document.getElementById("App").appendChild(div)
  }
  async dropedFile(e){
    let files = await fromEvent(e);
    this.loadFile({target:{ files : files }})
  }
  moveArrayItemToNewIndex(array, toIndex, fromIndex) {
    var copy = Object.assign([], array);
    if (toIndex >= copy.length) {
        var k = toIndex - copy.length;
        while ((k--) + 1) {
            copy.push(undefined);
        }
    }
    copy.splice(toIndex, 0, copy.splice(fromIndex, 1)[0]);
    return copy
    };
  sort = (newState)=>{
    let els = this.state.els;
    els = this.moveArrayItemToNewIndex(els,newState.newIndex,newState.oldIndex)
    this.setState({ els: els })
  }
  showDeleteModal=(id,page,name)=>{
    this.setState({dropModalShow:true,dropModalText:{page:page,id:id,name:name}})
  }
  showSettingsModal=()=>{
    this.setState({settingsModalShow:true})
  }
  deletePage = ()=>{
    let els = this.state.els;
    els = els.filter((e)=>{
      return e.id!==this.state.dropModalText.id;
    })
    this.setState({els:els,dropModalShow:false});
  }
  async downloadJpeg(img,page,docName){
    if(this.state.resize==="test"){
      img = await resizeImage(img,300,300,90);
      download(img, (page+1)+"-"+docName, "image/jpg");
    }else {
      download(img, (page+1)+"-"+docName, "image/jpg");
    }
  }
  downloadSinglePage = async(page,docName)=>{
    let file = this.state.loadedFiles.find((d)=>{ return d.filename ===docName });
    let doc = await PDFDocument.load(file.data);
    let newDoc = await PDFDocument.create();
    let newPages = await newDoc.copyPages(doc, [page])
    newPages.forEach((p)=>{
      newDoc.addPage(p)
    })
    newDoc.setCreator('splitandmergepdf')
    let pdfBytes = await newDoc.save();
    download(pdfBytes, (page+1)+"-"+docName, "application/pdf");
  }
  newDoc(){
    this.setState({newDocModalShow:false,loadedFiles:[],els:[]})
  }
  changeSettings(e){
    let val = e.target.type==="checkbox" ? e.target.checked : e.target.value;
    this.setState({[e.target.name] : val})
  }
  async extractImages(){
    let l = await extractImages(this.state.loadedFiles,this.state.resize,this.state.quality,this.state.resolution);

    this.setState({
      resizeModalShow:true,
      images : l
    })
  }
  render() {
    return (
      <div className="App" id="App" >
      <Dropzone noClick={true} className="dropzone" onDrop={(acceptedFiles) => this.loadFile({target : {files:acceptedFiles}})}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
          <Navbar bg="primary" variant="dark">
            <Container>
              <Navbar.Brand href="#home">
                <img src={logo} style={{height : "50px"}} />
                <span className="App-logo" >Split and merge PDF</span>
              </Navbar.Brand>
            </Container>
          </Navbar>
          <Container className="pt-2" >
          <div className="btn-group" >
            <label className="btn btn-primary"><FaUpload /> Select PDF file(s)
              <input {...getInputProps()} type="file" style={{"display":"none"}} onChange={this.loadFile} multiple />
            </label>
            <Button variant="secondary" onClick={()=>{this.setState({settingsModalShow:true})}}><FaCogs/> Options</Button>
            <Button variant="success" onClick={this.download}><FaDownload/> Download PDF</Button>
            <Button variant="secondary" onClick={()=>{this.setState({newDocModalShow:true})}}><FaFile/> New document</Button>
          </div>
          {this.state.error!==""&&(<p className="alert alert-danger mt-3">{this.state.error}</p>)}
          <div className="row mt-2">
            {this.state.els.length === 0 && (
              <div className="droparea m-3">
                <p>Select your PDF files or drop them here.
                <span className="text-muted small">All files are processed locally in your browser and no data will leave your computer.</span></p>
              </div>)}
            <ReactSortable
              list={this.state.els}
              onEnd={this.sort}
              setList={()=>{}}
              className="row"
            >
            {this.state.els.map((r,i)=>{ return (
              <div className="col-sm-6 col-md-3 col-lg-2 pb-2" key={i}>
                <div className="card">
                  <div className="card-body">
                  <div className="dragoverlay"><div className="small">Drag to change order</div><FaArrowLeft/><FaArrowRight/></div>
                  <div className="ccontainer" onClick={()=>this.zoom(r.img)}>
                    <img src={r.img}  />
                     <div className="middle"><div className="text"><MdZoomOutMap size={30}/><div className="small">Click to zoom</div></div></div>
                   </div>
                    <div className="card-title">
                    </div>
                    <div className="card-text">
                      <p className="small text-muted">{r.filename}</p>
                      <p className="small">Page in original PDF: {r.page+1}</p>
                      <Dropdown  >
                        <Dropdown.Toggle variant="secondary" className="col-12" id="dropdown-basic" size="sm">
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#" onClick={()=>this.showDeleteModal(r.id,r.page,r.filename)} className="text-danger"><FaTrash /> Remove page from doc</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={()=>this.downloadSinglePage(r.page,r.filename)}><GrDocumentPdf /> Save page as PDF</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={()=>this.downloadJpeg(r.img,r.page,r.filename)}><AiOutlineFileJpg /> Save page as JPEG</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ) })}
            </ReactSortable>
          </div>
        </Container>
        <div id="manual"></div>
        </div>
            )}

      </Dropzone>

        <Modal show={this.state.dropModalShow} onHide={()=>this.setState({dropModalShow:false})}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Page ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>You are about to delete page n. {this.state.dropModalText.page+1} from document {this.state.dropModalText.name}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>this.deletePage()}>
              Delete
            </Button>
            <Button variant="primary" onClick={()=>this.setState({dropModalShow:false})}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.newDocModalShow} onHide={()=>this.setState({newDocModalShow:false})}>
          <Modal.Header closeButton>
            <Modal.Title>New Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>Drop all files and start with new blank document ?</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={()=>this.newDoc()}>
              Yes
            </Button>
            <Button variant="secondary" onClick={()=>this.setState({newDocModalShow:false})}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.settingsModalShow} size="lg" onHide={()=>this.setState({settingsModalShow:false})}>
          <Modal.Header closeButton>
            <Modal.Title>Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" name="counter" checked={this.state.counter===true?true:false} onChange={(e)=>{this.changeSettings(e)}}/>
              <label className="form-check-label" htmlFor="exampleCheck1">Add page counter</label>
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck2" name="overlay" checked={this.state.overlay===true?true:false} onChange={(e)=>{this.changeSettings(e)}}/>
              <label className="form-check-label" htmlFor="exampleCheck2">Add footer overlay</label>
              <div className="form-text">Add white stripe to the footer of each page. This stripe should cover original page counter.</div>
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck3" name="resize"
              checked={this.state.resize===true?true:false} onChange={(e)=>{this.changeSettings(e)}}
              />
              <label className="form-check-label" htmlFor="exampleCheck3">Resize & compress images / scanned pages</label>
              <div className="form-text">This feature is suitable only for scanned documents. Making images smaller will result in smaller file size.</div>
            </div>
            <div className={this.state.resize===false?"text-muted":""}>
              <div className="mb-3 form-check">
                <label className="form-label" htmlFor="customRange2">Quality { this.state.quality }%</label>
                <input type="range" name="quality" className="form-range" min="10" max="100" name="quality"
                value={this.state.quality} onChange={(e)=>{this.changeSettings(e)}} disabled={this.state.resize===false?true:false}/>
              </div>
              <div className="mb-3 form-check">
                <label className="form-label" htmlFor="customRange3">Resolution {this.state.resolution}%</label>
                <input type="range" name="resolution" className="form-range" min="10" max="100" name="resolution"
                value={this.state.resolution} onChange={(e)=>{this.changeSettings(e)}} disabled={this.state.resize===false?true:false}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={()=>this.setState({settingsModalShow:false})}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.resizeModalShow} size="lg" onHide={()=>this.setState({resizeModalShow:false})}>
          <Modal.Header closeButton>
            <Modal.Title>Extracted images</Modal.Title>
          </Modal.Header>
          <Modal.Body className="resizeModal">
            <div>
            <p className="small">This feature allows to get images embeded in PDF files. <br/>To make images smaller go to Options and enable Resize. To convert whole PDF page into image use the menu button below each paage preview.</p>
              {this.state.loadedFiles.length===0&& (<p className="alert alert-warning">No files provided</p>)}
              {this.state.loadedFiles.length!==0&&this.state.images.length===0&& (<p className="alert alert-info">No images found in provided files</p>)}
              {this.state.images.map((img,i)=>{
              //  let url = URL.createObjectURL(new Blob([img.content.buffer], { type: 'image/'+img.type } /* (1) */));
                return <div>Image {i+1} <a download={(i+1)+"."+img.type} href={img.content}>Download</a> <br/><img src={img.content} /></div>
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={()=>this.setState({resizeModalShow:false})}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default App;
