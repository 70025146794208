import { PDFDocument,rgb, PDFDict, PDFName,PDFRawStream } from 'pdf-lib';
import download from 'downloadjs';
import Resizer from "./resizer";
const resizeFile = (file,quality,width,height) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
export async function extractImages(files,resize,quality,resolution){
  let imagesInDoc = [];
  for(let i=0;i<files.length;i++){
    var pdfDoc = await PDFDocument.load(files[i].data);
    for(let pn=0; pn<pdfDoc.getPages().length;pn++){
      let newDoc = await PDFDocument.create();
      let newPage = await newDoc.copyPages(pdfDoc, [pn])
      const enumeratedIndirectObjects = newPage[0].node.context.enumerateIndirectObjects();
      for(const x of enumeratedIndirectObjects){
      const pdfRef = x[0];
      const pdfObject = x[1];
        if (!(pdfObject instanceof PDFRawStream)) continue;
        const { dict } = pdfObject;
        const subtype = dict.get(PDFName.of('Subtype'));
        var width = dict.get(PDFName.of('Width'));
        var height = dict.get(PDFName.of('Height'));
        const name = dict.get(PDFName.of('Name'));
        const filter = dict.get(PDFName.of('Filter'));
        var newQuality = 100;
        if(resize===true){
          width = width*resolution/100;
          height = height*resolution/100;
          newQuality = quality;
        }
        if (subtype == PDFName.of('Image')) {
          let type = filter === PDFName.of('DCTDecode') ? 'jpg' : 'png';
          if(type==="jpg"){
            let url = URL.createObjectURL(new Blob([pdfObject.contents.buffer], { type: 'image/'+type } /* (1) */));
            //download(pdfObject.contents.buffer,name , "image/"+type);
            if(resize===false){
              imagesInDoc.push({
                name : name,
                width : width,
                height : height,
                type : type,
                content : url
              })
            }else{
              let newI = await resizeFile(url,newQuality,width,height)
              imagesInDoc.push({
                name : name,
                width : width,
                height : height,
                type : type,
                content : newI
              })
            }
        //
          }
         //FOUND IMAGE, NOW WHAT PAGE DOES IT EXIST ON
         //push image coordinates into an array for page

        }
      }
    }
  }
  return imagesInDoc;
}
export async function extractResize(pdfDoc,quality,resolution){
  const enumeratedIndirectObjects = pdfDoc[0].node.context.enumerateIndirectObjects();
  for(const x of enumeratedIndirectObjects){
  const pdfRef = x[0];
  const pdfObject = x[1];
    if (!(pdfObject instanceof PDFRawStream)) continue;
    const { dict } = pdfObject;
    const subtype = dict.get(PDFName.of('Subtype'));
    const width = dict.get(PDFName.of('Width'));
    const height = dict.get(PDFName.of('Height'));
    const name = dict.get(PDFName.of('Name'));
    const filter = dict.get(PDFName.of('Filter'));
    const newWidth = width*resolution/100;
    const newHeight = height*resolution/100;
    if (subtype == PDFName.of('Image')) {
      let type = filter === PDFName.of('DCTDecode') ? 'jpg' : 'png';
      console.log(width,newWidth,height,newHeight)
      if(type==="jpg"){

        let url = URL.createObjectURL(new Blob([pdfObject.contents.buffer], { type: 'image/'+type } /* (1) */));
        let res = width*resolution;
        let newI = await resizeFile(url,quality,newWidth,newHeight)
        return {
          name : name,
          width : width,
          height : height,
          type : type,
          content : newI
        }
    //    download(newI,name , "image/"+type);
      }
     //FOUND IMAGE, NOW WHAT PAGE DOES IT EXIST ON
     //push image coordinates into an array for page

    }
  }
  return false;
}
export async function resizeImage(files,quality,width,height){
  console.log(files)
  let newI = await resizeFile(files,quality,width,height);
  return newI
}
